import axios from 'axios';

import CONFIG from '../utils/config';

export const submitLead = (data, appId, cid, campaignID) =>
	axios.post(
		`${CONFIG.WHITELABEL_API_URL}/lead`,
		mapSurveyDataToPost(data, appId, cid, campaignID)
	);
export const getReportById = (id, appId) =>
	axios.get(`${CONFIG.WHITELABEL_API_URL}/lead?id=${id}&appid=${appId}`);
export const isValidData8PhoneNumber = (number, appId) =>
	axios.get(
		`${CONFIG.WHITELABEL_API_URL}/helper?phoneNumber=${number}&appid=${appId}`
	);

const service = {
	submitLead,
	getReportById,
	isValidData8PhoneNumber
};

function mapSurveyDataToPost(data, appId, cid, campaignID) {
	return {
		appId,
		cid: cid,
		campaignID: campaignID,
		person: {
			phoneNumber: data.phoneNumber.replace(/[^0-9\+]/gi, ''),
			forename: data.forename,
			surname: data.surname,
			emailAddress: data.emailAddress
		},
		answers: {
			country: data.country,
			residentialStatus: data.residentialStatus,
			worries: data.worries,
			numberOfLenders: data.numberOfLenders,
			amountOwed: data.amountOwed,
			payingMonthlyAmount: data.payingMonthlyAmount,
			otherDebtsAmount: data.otherDebtsAmount,
			utilitiesAmount: data.utilitiesAmount,
			groceryAmount: data.groceryAmount,
			travelAmount: data.travelAmount,
			childcareAmount: data.childcareAmount,
			dayToDayLivingAmount: data.dayToDayLivingAmount,
			tvAndTelecomsAmount: data.tvAndTelecomsAmount,
			rentAmount: data.rentAmount,
			monthlyEarnings: data.monthlyEarnings,
			monthlyBenefits: data.monthlyBenefits || 0,
			assetsOverThreshold: data.assetsOverThreshold
		},
		consent: [
			{
				channel: 'phone',
				granted: data.phoneConsent === 'Yes'
			},
			{
				channel: 'sms',
				granted: data.smsConsent === 'Yes'
			},
			{
				channel: 'email',
				granted: data.emailConsent === 'Yes'
			}
		]
	};
}

export default service;
