import styled from 'styled-components';

const Card = styled.div`
  background-color:  ${props => props.theme.colors.white};
  text-align:center;
  margin-bottom: 50px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: 55px 53px 72px;
`;

export default Card;